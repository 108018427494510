* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Poppins, sans-serif;
  color: white;
  background-color: #292AB7;
}
@media screen and (max-width: 960px) {
  
  /* Main Content Section */
  .content-section {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0%;
  }

  .cards-section {
    flex-direction: column;
    justify-content: contain;
    gap: 1rem;/* Increase spacing between cards */
    width: 100%;
    padding: 1%;/* Increase padding around cards */
  }
    .apply_btn {
      width: auto; /* Make the button full-width */
      padding: 1rem;
      margin-top: 1rem;
    }
  

.background-image img {
  width: 50%;    /* Image will take up the full width */
  max-height: 500px;  /* Limit the maximum height */
  height: auto;   /* Adjust the height to maintain aspect ratio */

}

  /* Text Content */
  .text-content {
    padding: 1rem;
    text-align: center;
    width: 100%;
    margin-right: 0;
  }

  .text-content h1 {
    margin-bottom: 1rem;
    font-size: calc(4vh + 4vw);
  }

  .text-content h2 {
    font-size: calc(2vh + 2vw);
    margin-bottom: 1rem;
  }

  /* Adjust the cards section layout */
  .cards-section {
    flex-direction: column;
    align-items: center;
    padding: 1rem;
  }

  .card {
    width: 80%; /* Make cards take up most of the screen */
    margin-bottom: 1rem;
  }

  /* Form Container */
  .form-container {
    width: 90%;
    max-width: 400px;
    margin: 0 auto; /* Center the form container */
    padding: 1rem;
    position: relative;
    top: 10px;
  }

  /* Apply Button */
  .apply_btn {
    width: 100%; /* Make the button full-width */
    padding: 1rem;
    margin-top: 1rem;
  }

  /* Adjust button text */
  .apply_btn, .button, .signup-btn {
    font-size: 1.5rem;
    padding: 0.8rem 1.5rem;
  }

  /* Adjust logo size */
  #logo img {
    width: 120px;
    height: auto;
  }

  /* Adjust other content containers */
  .content-container {
    padding: 10px;
    flex-direction: column;
    justify-content: center;
  }

  /* Update any headers and text for better readability */

  .cards-section .cta-text h3 {
    font-size: calc(3vh + 3vw);
  }

  /* Hide larger elements that might not be needed on smaller screens */
  .background-image img {
    object-fit: cover;
  }

  
  .hide-content {
    display: none;
  }


  .form-container {
    position: fixed;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    background-color: rgba(30, 30, 228, 0.907);
    padding: 1rem;
    display: flex; /* Use flexbox */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
  }


  .content-container {
    position: relative;
    z-index: 0;
    padding: 10px; 
  }

  .apply_btn {
    display: block;
  } 

  .button {
    border-radius: 6px;
    box-shadow: 0px 4px 30px 0px rgba(30, 30, 30, 0.20);
    padding: 12px 109px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
  }

  .intro-text3-layout{
    margin-top: 20% !important;

  }
}

@media screen and (min-width: 961px) {
  .form-container {
    position: fixed;
    top: 80px; /* Adjust based on your nav height */
    background-color: rgba(35, 35, 173, 0.907);
    transform:  translate(-10%, -10%);
    display: flex; /* Use flexbox */
    align-items: center; /* Center vertically */
    margin-top: 0;
    margin-right: 0;
    padding: 1rem;
    z-index: 1000;

  }
  
}

.main-container {
  min-height: 100vh;
  background: linear-gradient(90deg, #00B4D8 0%, #2A74A4 36.34%, #292AB7 100%);
  color: white;
  position: relative;
  overflow: hidden;
  
}
.main-container::before {
  content: '';
  position: relative;
  right: 0;
  top: 0;
  width: 50%;
  height: 100%;
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.20);
}

.content-section::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.044); /* Adjust opacity as needed */
  z-index: 1;
}

/* Navigation */
.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1rem;
}

.logo img {
  height: 40px;
}

/* Position next to Apply button */
.apply-button .form-field {
  top: 100%;
  left: 2rem;
  margin-top: 1rem;
 }
 
.apply-button {
  background: white;
  color: #3B82F6;
  box-shadow: 0px 4px 30px 0px rgba(30, 30, 30, 0.20);
  font-family: Poppins;
  font-size: calc(1.5vh + 1vw);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  padding: 0.5rem 1.5rem;
  border-radius: 20px;
  border: none;
  cursor: pointer;
}

/* Main Content Section */
.content-section {
  position: relative;
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  min-height: calc(100vh - 80px); /* Adjust based on nav height */
  z-index: 2;
}

.text-content {
  max-width: 800px; /* Control content width */
  text-align: center;
  margin-right: -10%;
  padding: 1.5rem;
  height: auto;
  flex-shrink: 1;
}

.text-content h1 {
  font-size: calc(4.5vh + 4vw);
  font-weight: bold;
  margin-bottom: 1rem;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.3);
  height: auto;
  flex-shrink: 1;
}

.text-content h2 {
  font-size: calc(3.5vh + 3vw);
  margin-top: 10%;
  margin-bottom: 2rem;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.3);
  z-index: 5;
}

.feature-text {
  font-size: calc(1.5vh + 1vw);
  font-style: italic;
  margin-top: 15%; 
  height: auto;

}

.text-content h3 {
  font-size: 28px;
  text-align: initial;
  margin-left: 10%;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.3);
  height: auto;
  font-weight: bold;
}

.cta-text h2 {
  font-size: calc(2.0vh + 1.5vw);
  margin-top: 20%;
  margin-left: 10%;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.3);
  height: auto;
}

  .cta-text p {
    font-size: calc(0.5vh + 1vw);
    text-align: center;
    max-width: 600px;
    margin-left: 10%;
    height: auto;
    z-index: 2;

  }

.background-image {
  position: relative;
  right: 0;
  margin-top: 15%;
  width: 60%;
  height: 80%;
  z-index: -1;
  flex-shrink: 1;
}

.background-image img {
  width: 100%;
  height: 80%;
  object-fit: cover;
}

.mid-container {
  display: flex;
  justify-content: center;
  
}

.mid-container.mid-text {
  justify-content: center;
  margin-bottom: 4rem;
 }

.mid-text h4 {
  text-align: center;
  font-size: 18px;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.3);  
  max-width: 800px;
  margin: 0 auto;
  color: white;
  font-style: italic;
  margin-bottom: 1.5rem;
  padding:auto;
 }

 .mid-text h3 {
  text-align: center;
  font-size: 28px;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.3);
  height: auto;
  font-weight: bold;
  margin-bottom: 3rem;
  padding:auto;
}

.cards-container {
  padding: 5% 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Cards Section */
.cards-section {
    display: flex;
    justify-content: center;
    gap: 1rem;/* Increase spacing between cards */
    width: 100%;
    padding: 4rem 1%;/* Increase padding around cards */
 
}

.cards-section .cta-text {
  text-align: center;
  margin-bottom: 4rem;
 }
 

 .cards-container .cta-text h4 {
  font-size: 1rem;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.3);
  max-width: 800px;
  margin: 0 auto;
  color: white;
  font-weight: bold;
 }


 .cards-container .cta-text h3 {
  text-align: center;
  font-size: 1rem;
  max-width: 800px;
  color: #D0ffff;
 }

.card {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  width: 450px; /* Increase card width */
  padding: 1rem
}

.card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 1rem;
}

.form-field {
  border-radius: 11.078px;
  margin-bottom: 15px;
  border: 1.1px solid #FFF;
  max-width: 300px;
}

/* Sign Up Button */
.signup-button-container {
  text-align: center;
  padding: 2rem;
}

.signup-button-container button {
  background: rgb(6, 167, 188);
  color: #fafbfc;
  padding: 1rem 3rem;
  border: none;
  border-radius: 25px;
  font-size: 1.2rem;
  font-weight: 700;
  cursor: pointer;
}


/* Response Messages */
.success {
  font-size: 1.2rem;
  font-weight: 700;
  color: #10B981;
  text-align: center;
  margin-top: 1rem;
}

.error {
  font-size: 1.2rem;
  font-weight: 500;
  color: #EF4444;
  text-align: center;
  margin-top: 1rem;
}

/* Responsive Design */
@media (max-width: 768px) {
  .content-section {
    flex-direction: inherit;
  }
  
  .background-image {
    position: relative;
    width: 100%;
    height: 80vh
  }
  
  .cards-section {
    flex-direction: column;
    align-items: center;
  }
  
  .text-content h1 {
    font-size: 2.5rem;
  }
  
  .text-content h2 {
    font-size: 2rem;
  }
}
